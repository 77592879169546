import Permissions from '../permissions'

export default [
  {
    name: '(PRODA) All Invoices',
    desc: 'Manage invoices',
    icon: 'profile',
    link: '/invoices-list/all',
    enable: true,
    permission: Permissions.INVOICE.INFO.LIST,
    submenu: [
      {
        name: '(PRODA) Provider Invoices',
        desc: 'Manage Provider invoices',
        icon: 'project',
        link: '/invoices-list/pm',
        enable: true,
        permission: Permissions.INVOICE.INFO.LIST
      },
      {
        name: '(PRODA) PM Fees',
        desc: 'Manage PM Fees',
        icon: 'tool',
        link: '/invoices-list/std',
        enable: true,
        permission: Permissions.INVOICE.INFO.LIST
      }
    ]
  },
]
