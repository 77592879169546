import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/invoices-pace'

export default {
  exportInvoicePost (values) {
    return requestFile(`${url}/export/all`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  exportInvoiceFilePost (values) {
    return requestFile(`${url}/export/file`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  exportInvoiceFilePostById (id, values) {
    return requestFile(`${url}/${id}/export/file`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getByRefId (refId) {
    return request(`${url}/ref-id/${refId}`)
  },
  getAllDropdowns (filter, text) {
    return request(`${url}/dropdown${queryString.stringify({ filter: JSON.stringify(filter || {}), text })}`)
  },
  getSummary (filter) {
    return request(`${url}/summary${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  saveAndProcess (values) {
    return request(`${url}/process`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveAsDraft (values) {
    return request(`${url}/draft`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  update (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  revertDraft (id, values) {
    return request(`${url}/${id}/revert`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  updateAndProcess (id, values) {
    return request(`${url}/${id}/process`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  updateDraft (id, values) {
    return request(`${url}/${id}/draft`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
