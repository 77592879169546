import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { apiHostname } from '../../../config'
import { auth, common, exportFile, formatter, queryString, validator } from '../../../util'
import { authService, statementService } from '../../../services'
import { ExportType, InvoiceListType, StatementMenu } from '../../../constants'

// UI
import { Button, ControlLabel, Checkbox, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

const { confirm } = Modal

const pageSize = 20
const Option = Select.Option

const getTitle = (type) => {
  let title

  if (type === InvoiceListType.INV_LIST_PM) {
    title = '(PRODA) PM SB Statements'
  } else if (type === InvoiceListType.INV_LIST_STD) {
    title = '(PRODA) Std SB Statements'
  } else {
    title = '(PRODA) Statement Periods'
  }

  return title
}

export class StatementListClient extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      filterType: '',
      list: { list: [], total: 0 },
      detail: {},
      loading: false,
      loadingList: false,
      searching: false,
      searchText: '',
      sort: {},
      selectList: [],
      selectListAll: false,
      selectErrorMsg: '',
      title: ''
    }
    this.onSearchName = debounce(this.onSearchName, 500)
    // this.onSelectActive = this.onSelectActive.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { match } = nextProps

    const { type = InvoiceListType.INV_LIST_PM } = common.getPath(match)
    let title = prevState.title

    if (prevState.filterType !== type) {
      title = getTitle(type)
    }

    const state = {
      ...prevState,
      filterType: type,
      title
    }

    return state
  }

  componentDidMount () {
    this.fetchPeriod()
  }

  fetch () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchListClient({ isInitialised: false, currentPage, filter, loading, searchText, sort })
  }

  onSearchName (value) {
    const { currentPage, filter, loading, sort } = this.state
    this.setState({ searching: true })

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { booking_number: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchListClient({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isBeyondMonth (d1, d2) {
    const d1s = formatter.toMoment(d1)
    const d2s = formatter.toMoment(d2)

    if (d2s.isAfter(d1s)) {
      return d2s.isSameOrAfter(d1s, 'month')
    } else {
      return false
    }
  }

  getRowTextStyle (isClientActive, isBeyond) {
    let color = undefined
    if (isClientActive) {
      if (isBeyond) {
        color = '#FF3300'
      } else {
        color = undefined
      }
    } else {
      if (isBeyond) {
        color = '#FF330044'
      } else {
        color = '#ccc'
      }
    }

    return color
  }

  render () {
    const { history } = this.props
    const { currentPage, filterType, detail, list, loading, loadingList, searching, selectListAll, selectList, title } = this.state
    const isSelect = selectList.length > 0

    const columns = [
      {
        title: 'Participant',
        width: 3,
        render: ({ client_fullname, client_id, client_ref_id, client_active, period_end_date, period_date_to }) => {
        const isBeyond = this.isBeyondMonth(period_end_date, period_date_to)
        return <div>
          <a style={{color: this.getRowTextStyle(client_active, isBeyond)}} href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>
            {client_fullname}
          </a>
        </div>
        }
      },
      {
        title: 'Booking Number',
        width: 3,
        render: ({ booking_number, client_active, period_end_date, period_date_to }) => {
          const isBeyond = this.isBeyondMonth(period_end_date, period_date_to)

          return <div style={{color: this.getRowTextStyle(client_active, isBeyond)}}>{booking_number}</div>
        }
      },
      {
        title: 'SB Period',
        width: 4,
        render: ({ client_active, period_start_date, period_end_date, period_date_to }) => {
          const isBeyond = this.isBeyondMonth(period_end_date, period_date_to)

          return <div style={{color: this.getRowTextStyle(client_active, isBeyond)}}>{formatter.toShortDate(period_start_date)} - {formatter.toShortDate(period_end_date)}</div>
        }
      },
      {
        title: 'Opening Bal',
        width: 3,
        render: ({ client_active, total_last_month_value, cum_rcv_total, period_end_date, period_date_to }) => {
          const isBeyond = this.isBeyondMonth(period_end_date, period_date_to)

          return <div style={{color: this.getRowTextStyle(client_active, isBeyond)}}>{formatter.toPrice(parseFloat(total_last_month_value) + parseFloat(cum_rcv_total))}</div>
        }
      },
      {
        title: 'Total Paid Amount',
        width: 3,
        render:({ client_active, cum_rcv_total, period_end_date, period_date_to }) => {
          const isBeyond = this.isBeyondMonth(period_end_date, period_date_to)

          return <div style={{color: this.getRowTextStyle(client_active, isBeyond)}}>{formatter.toPrice(cum_rcv_total)}</div>
        }
      },
      {
        title: 'Closing Bal',
        width: 3,
        render: ({ client_active, total_last_month_value, period_end_date, period_date_to }) => {
          const isBeyond = this.isBeyondMonth(period_end_date, period_date_to)

          return <div style={{color: this.getRowTextStyle(client_active, isBeyond)}}>{formatter.toPrice(total_last_month_value)}</div>
        }
      },
      {
        title: 'Total Credit Balance',
        width: 3,
        render: ({ client_active, cum_credit_remaining_amount, period_end_date, period_date_to }) => {
          const isBeyond = this.isBeyondMonth(period_end_date, period_date_to)

          return <div style={{color: this.getRowTextStyle(client_active, isBeyond)}}>{formatter.toPrice(cum_credit_remaining_amount)}</div>
        }
      },
      {
        title: '',
        width: 2,
        render: (item) => {
          const isChecked = selectList.find(e => e.budget_id === item.budget_id) || false
          // const isEmail = item.client_authorize_rep_email || item.client_email
          const isEmail = !!item.client_statement_email

          return (<div className='action-buttons' key={`select${item.budget_id}`}>
            <div style={{paddingRight: '15px'}}>
              <Checkbox
                checked={isChecked}
                onClick={(e) => this.updateItemSelect(e, {
                  id: item.id,
                  budget_id: item.budget_id,
                  is_email: isEmail,
                  booking_number: item.booking_number,
                  budget_client_id: item.budget_client_id
                })}
              />
            </div>
            <Tooltip mouseLeaveDelay={0} title='Download'>
              <div style={{cursor: 'pointer'}} onClick={() => this.getStatement(item, true, false)}>
                <Icon type='download' />
              </div>
            </Tooltip>
            <Tooltip mouseLeaveDelay={0} title={isEmail ? 'Send Email' : 'No Email Recipient for Statement'}>
              { isEmail
                ? <div style={{cursor: 'pointer'}} onClick={() => this.getStatement(item, false, true)}>
                  <Icon type='mail' />
                </div>
                : <div style={{cursor: 'default'}}>
                  <Icon type='mail' style={{color: '#ccc'}} />
                </div> }
            </Tooltip>
          </div>)
        }
      },
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={StatementMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={title} total={detail && detail.id ? detail.name : ''}>
            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='action-buttons-sc'>
            { isSelect && filterType === InvoiceListType.INV_LIST_STD ? <Button style={{backgroundColor: '#34ebb4'}} key='submitaba' feedback={loadingList} onClick={() => this.preStatement(ExportType.TYPE_SB_PRODA_V2)}>Download Pymt Req</Button> : null }
            { isSelect ? <Button style={{backgroundColor: '#3d34eb'}} key='submit1' feedback={loadingList} onClick={() => this.preStatement(ExportType.TYPE_STMT, false, true)}>Download Selected</Button> : null }
            { isSelect ? <Button style={{backgroundColor: '#ebc034'}} key='submit2' feedback={loadingList} onClick={() => this.preStatement(ExportType.TYPE_STMT, true, false)}>Email Selected</Button> : null }
            { isSelect ? <Button key='submit3' feedback={loadingList} onClick={() => this.preStatement(ExportType.TYPE_STMT, true, true)}>Download & Email Selected</Button> : null }
          </div>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={9}>
                <ControlLabel>Participant Name, Booking Number</ControlLabel>
                <SearchInput placeholder='Search statement' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              <Col lg={10} />
              <Col lg={5}>
                { list && list.list && validator.isNotEmptyArray(list.list) && !loading
                  ? <div className='inv-sec-row-left' style={{marginTop: '12px'}}>
                    <div style={{marginRight: '10px', fontSize: '11px', fontWeight: '400', color: '#333'}}>Select / Deselect All ({selectList.length} item{selectList.length === 1 ? '' : 's'} selected)</div>
                    <Checkbox
                      checked={selectListAll}
                      onClick={f => this.updateItemSelectAll(f)}
                    />
                  </div>
                  : null }

              </Col>
            </Row>
          </Page.Filter>

          <Skeleton loading={loading} active>
            <Spin spinning={loadingList}>
              <List cols={columns} rows={list.list} />
            </Spin>
          </Skeleton>

          <Pager
            size={pageSize}
            total={list.total}
            totalText={`Total ${list.total} statements`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchListClient({ isInitialised: false, currentPage, filter, searchText, sort })
  }

  fetchPeriod = async () => {
    this.setState({ loading: true })

    const date = this.getDate()
    const detail = await statementService.getDate(date)

    if (detail && detail.id) {
      this.setState({ detail }, () => {
        this.fetch()
      })
    }
  }

  fetchListClient = async ({ isInitialised = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    const { detail, filterType } = this.state

    try {
      this.setState({ currentPage, loading: isInitialised ? false : true, loadingList: isInitialised ? true : false })

      if (filterType === InvoiceListType.INV_LIST_PM) {
        filter.is_monthly_fee_main_set = { condition: '=', value: false }
      } else if (filterType === InvoiceListType.INV_LIST_STD) {
        filter.is_monthly_fee_main_set = { condition: '=', value: true }
      } else {
        delete filter.is_monthly_fee_main_set
      }

      const list = await statementService.listStatementClientByPage(detail.id, currentPage, pageSize, filter, sort, searchText)
      // console.log('fetch list client', list)
      if (list && list.list) {
        let selectList = []
        this.setState({ list, loading: false, loadingList: false, searching: false, selectListAll: false }, () => {
          this.checkItemSelect()
        })
      } else {
        this.setState({ loading: false, loadingList: false, searching: false, selectList: [], selectListAll: false })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load period list successfully. Please try again later.')
      this.setState({ searching: false, selectList: [], selectListAll: false })
    }
  }

  checkItemSelect = () => {
    const { list, selectList } = this.state
    let allSelected = null

    if (list.list && validator.isArray(list.list)) {
      for (let i = 0; i < list.list.length; i++) {
        const itm = list.list[i]

        if (selectList.findIndex(e => e.budget_id === itm.budget_id) === -1) {
          allSelected = false
          break
        }
      }

      if (allSelected !== false) {
        allSelected = true
      }
    }


    this.setState({ selectListAll: !!allSelected })
  }

  updateItemSelect = (e, data) => {
    const check = e.target.checked
    let { selectList, list } = this.state

    const c = selectList.findIndex(e => e.budget_id === data.budget_id)
    if (c > -1) {
      selectList.splice(c, 1)
    } else {
      selectList.push(data)
    }

    this.setState({
      selectList,
      selectErrorMsg: ''
    }, () => {
      this.checkItemSelect()
    })
  }

  updateItemSelectAll = (e) => {
    const check = e.target.checked

    const { selectList, list } = this.state

    let newSelectList = selectList.slice()

    if (list.list && validator.isArray(list.list)) {
      for (let i = 0; i < list.list.length; i++) {
        const itm = list.list[i]
        const idx = newSelectList.findIndex(e => e.budget_id === itm.budget_id)
        const isEmail = !!itm.client_statement_email

        if (check) {
          if (idx > -1) {
            newSelectList.splice(idx, 1)
          }
        } else {
          if (idx === -1) {
            newSelectList.push({
              id: itm.id,
              budget_id: itm.budget_id,
              is_email: isEmail,
              booking_number: itm.booking_number,
              budget_client_id: itm.budget_client_id
            })
          }
        }
      }

      this.setState({
        selectListAll: !check,
        selectList: newSelectList.slice()
      })
    }
  }

  getStatement = async (item, isDownload = true, isSendEmail = false) => {
    const { budget_client_id: clientId, client_fullname: clientName } = item
    const name = item.period_name.replace(/ /g, '')
    const filter = {
      id: item.id,
      booking_number: item.booking_number,
      budget_id: item.budget_id,
      budget_client_id: clientId,
      is_download: isDownload,
      is_send_email: isSendEmail
    }
    const filename = `${clientName ? clientName.replace(/ /g, '') : clientName}-${name}`
    const r = await exportFile.fetchStatement(filter, filename)

    if (r && r.id && isSendEmail) {
      notify.success('Email Sent Successfully', 'Statement has been sent out by email successfully.')
    } else if (isSendEmail) {
      notify.error('Unable to send email', 'Statement is not sent out by email. Please try again later.')
    }
  }

  preStatement = (type, isSendEmail, isDownload) => {
    const { getPeriodAba, getStatementMulti } = this
    const { selectList = [], list } = this.state

    const selectedMsg = `(${selectList.length} item${selectList.length === 1 ? ' is ' : 's are'} selected)`

    const title = isSendEmail && isDownload
      ? `Are you sure to export and email selected items?`
      : isSendEmail
        ? `Are you sure to email selected items?`
        : `Are you sure to export selected items?`

    if (validator.isNotEmptyArray(selectList)) {
      const isEmailRecipientMissing = selectList.find(e => e.is_email === false)

      const content = isSendEmail && isEmailRecipientMissing
        ? <div>
            <b style={{color: 'red'}}>Some participants do not have valid email recipient.</b><br />
            Press OK to continue, Cancel to return
          </div>
        : <div>Press OK to continue, Cancel to return.</div>

      confirm({
        title: <div>{title}<br />{selectedMsg}</div>,
        content: content,
        onOk () {
          type === ExportType.TYPE_SB_PRODA || type === ExportType.TYPE_SB_PRODA_V2
          ? getPeriodAba(type)
          : getStatementMulti(type, isSendEmail, isDownload)
        }
      })
    } else {
      this.setState({ selectErrorMsg: 'Item is not selected.' })
    }
  }

  getPeriodAba = async (type) => {
    const { list, selectList, detail } = this.state
    const id = detail.id

    if (id) {
      this.setState({ loadingList: true })

      await exportFile.fetchFiles(id, type, null, selectList)

      setTimeout(() => {
        this.setState({ loadingList: false })
      }, 3000)
    } else {
      notify.error('Unable to get ABA file', 'No Period Details available')
    }
  }

  getStatementMulti = async (type, isSendEmail = false, isDownload = true) => {
    const { detail, list, selectList } = this.state
    const { list: aList } = list

    this.setState({ loadingList: true })

    const body = {
      id: detail.id,
      data: selectList,
      is_send_email: isSendEmail,
      is_download: isDownload
    }

    const r = await exportFile.fetchStatementMulti(body)

    if (isSendEmail) {
      if (r && r.id) {
        notify.success('Email Sent Successfully', 'Statements have been sent out by email successfully.')
      }
      this.setState({ loadingList: false })
    } else {
      setTimeout(() => {
        this.setState({ loadingList: false })
      }, 3000)
    }
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }

  getDate = () => {
    const { match } = this.props
    const { params } = match
    const { date = '' } = params
    return date
  }

  getType = () => {
    const { match } = this.props
    const { params } = match
    const { type = '' } = params
    return type
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.Invoice }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatementListClient)
