import Permissions from '../permissions'

export default [
  {
    name: '(PRODA) Statement Periods',
    desc: 'Manage all periods',
    icon: 'database',
    link: '/statement/period/all',
    similar: '/statement/period/all',
    enable: true,
    permission: Permissions.STATEMENT.INFO.LIST,
    submenu: [
      {
        name: '(PRODA) PM SB Statements',
        desc: 'Manage PM Statements',
        icon: 'project',
        link: '/statement/period/pm',
        similar: '/statement/period/pm',
        enable: true,
        permission: Permissions.STATEMENT.INFO.LIST
      },
      {
        name: '(PRODA) Std SB Statements',
        desc: 'Manage Standard Booking Statements',
        icon: 'tool',
        link: '/statement/period/std',
        similar: '/statement/period/std',
        enable: true,
        permission: Permissions.STATEMENT.INFO.LIST
      }
    ]
  },
  {
    name: 'Statement Periods',
    desc: 'Manage all periods',
    icon: 'database',
    link: '/statement-pace/period/all',
    similar: '/statement-pace/period/all',
    enable: true,
    permission: Permissions.STATEMENT.INFO_PACE.LIST,
    submenu: [
      {
        name: 'PM SB Statements',
        desc: 'Manage PM Statements',
        icon: 'project',
        link: '/statement-pace/period/pm',
        similar: '/statement-pace/period/pm',
        enable: true,
        permission: Permissions.STATEMENT.INFO_PACE.LIST
      },
      {
        name: 'Std SB Statements',
        desc: 'Manage Standard Booking Statements',
        icon: 'tool',
        link: '/statement-pace/period/std',
        similar: '/statement-pace/period/std',
        enable: true,
        permission: Permissions.STATEMENT.INFO_PACE.LIST
      }
    ]
  }
]
