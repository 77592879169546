export const SET_FILE_ATTACHMENT_COUNT = 'SET_FILE_ATTACHMENT_COUNT'
export const SET_FORM_ATTACHMENT_COUNT = 'SET_FORM_ATTACHMENT_COUNT'
export const SET_ON_SAVE = 'SET_ON_SAVE'
export const SET_ON_REVERT_TO_DRAFT = 'SET_ON_REVERT_TO_DRAFT'
export const SET_ON_SAVE_AND_PROCESS = 'SET_ON_SAVE_AND_PROCESS'
export const SET_ON_SAVE_AS_DRAFT = 'SET_ON_SAVE_AS_DRAFT'
export const SET_SELECTED_INVOICE = 'SET_SELECTED_INVOICE'
export const SET_SELECTED_INVOICE_TYPE = 'SET_SELECTED_INVOICE_TYPE'

export const setFileAttachmentCount = (fileAttachmentCount) => {
  return {
    type: SET_FILE_ATTACHMENT_COUNT,
    fileAttachmentCount
  }
}

export const setFormAttachmentCount = (formAttachmentCount) => {
  return {
    type: SET_FORM_ATTACHMENT_COUNT,
    formAttachmentCount
  }
}

export const setOnRevertToDraft = (onRevertToDraft) => {
  return {
    type: SET_ON_REVERT_TO_DRAFT,
    onRevertToDraft
  }
}

export const setOnSave = (onSave) => {
  return {
    type: SET_ON_SAVE,
    onSave
  }
}

export const setOnSaveAndProcess = (onSaveAndProcess) => {
  return {
    type: SET_ON_SAVE_AND_PROCESS,
    onSaveAndProcess
  }
}

export const setOnSaveAsDraft = (onSaveAsDraft) => {
  return {
    type: SET_ON_SAVE_AS_DRAFT,
    onSaveAsDraft
  }
}

export const setSelectedInvoice = (selectedInvoice) => {
  return {
    type: SET_SELECTED_INVOICE,
    selectedInvoice
  }
}

export const setSelectedInvoiceType = (selectedInvoiceType) => {
  return {
    type: SET_SELECTED_INVOICE_TYPE,
    selectedInvoiceType
  }
}