import Permissions from '../permissions'

export default [
  {
    name: 'Participant Service Booking Report',
    icon: 'line-chart',
    link: '/reports/participant-sb-report',
    desc: 'Generate Participant Service Booking Report',
    enable: true,
    permission: Permissions.REPORT.REPORT_SB.LIST
  },
  {
    name: 'Participant To Receive Invoices Report',
    icon: 'bar-chart',
    link: '/reports/to-receive',
    desc: 'Generate Participant To Receive Invoices Report',
    enable: true,
    permission: Permissions.REPORT.REPORT_PENDING_INVOICES.LIST
  },
  {
    name: '(PRODA) Report Generate Request',
    icon: 'hdd',
    link: '/reports/custom-generate',
    desc: 'Generate Custom Report by per Request',
    enable: true,
    permission: Permissions.REPORT.SCHEDULER.LIST
  },
  {
    name: 'Report Generate Request',
    icon: 'hdd',
    link: '/reports-pace/custom-generate',
    desc: 'Generate Custom Report by per Request',
    enable: true,
    permission: Permissions.REPORT.SCHEDULER_PACE.LIST
  },
  {
    name: 'Report Recipient Setting',
    icon: 'setting',
    link: '/reports/setting',
    desc: 'Edit Report Email Recipient Setting',
    enable: true,
    permission: Permissions.REPORT.RECIPIENT_SETTING.LIST
  },
]
