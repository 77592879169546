/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import BigNumber from 'bignumber.js'
import { debounce } from 'lodash'
import Moment from 'moment-timezone'
import { clientService, commonService, linkRequestService, providerService, settingGeneralService, settingReasonService } from '../../../services'
import { fetchingClients, fetchClientLimit, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates, Permissions } from '../../../constants'
import { auth, formatter, log, validator } from '../../../util'

// UI
import { CustomIdentifierList, Loading, Page, Panel, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'

import './styles.css'
import ClientActivity from '../ActivityLog'
import ClientBudget from '../Budget'
import ClientCredit from '../Credit'
import ClientFile from '../File'
import ClientInvoice from '../Invoices'
import ClientPlanPeriod from '../PlanPeriodPace'
import ClientStatement from '../Statement'
import ClientCreditPace from '../CreditPace'
import ClientInvoicePace from '../InvoicesPace'

const { Item: FormItem } = Form
const { confirm } = Modal
const TabPane = Tabs.TabPane
const { TextArea } = Input
const Option = Select.Option

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const urlRedirect = '/participants'
const NDISNoDuplicatedMsg = 'Duplicate record found.'
const AltNDISNoDuplicatedMsg = 'Duplicate record found.'
const totalComparisonFields = 5

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 18 }
}

const sideBySidePrivateLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideExtraFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 10 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 8, path: '/custom-identifier' },
  { tabId: 3, path: '/booking' },
  { tabId: 9, path: '/plan-period-pace' },
  { tabId: 7, path: '/credits' },
  { tabId: 10, path: '/credits-pace' },
  { tabId: 11, path: '/invoices-pace' },
  { tabId: 4, path: '/invoices' },
  { tabId: 5, path: '/statements' },
  { tabId: 6, path: '/files' },
  { tabId: 2, path: '/logs' }
]

export class Client extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      activeMsg: '',
      item: {},
      loading: false,
      loadingSave: false,
      loadingCheckNDISNo: false,
      loadingCheckAltNDISNo: false,
      loadingLinkClientId: false,
      shouldActive: false,
      showAuthorizeRep: false,
      showSupportCoord: false,
      showPMAuthorizeAmount: false,
      showSave: false,
      showEdit: true,
      showExitedReason: false,
      showExitedToggle: false,
      showLinkModal: false,
      isExitedToggleChange: false,
      isNDISNoDuplicated: false,
      isAltNDISNoDuplicated: false,
      currentAge: '',
      providerList: [],
      providerInfo: {},
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      pcpUser: {},
      pcpUserShareToken: {},
      similarFieldCount: 0,
      similarFieldPercent: 0,

      // settings
      genders: [],
      heights: [],
      languages: [],
      reasons: [],
      relationships: [],
      suburbs: [],
      settings: []
    }

    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
    this.debounceValidateNDISNumber = debounce(this.debounceValidateNDISNumber, 1000)
    this.debounceValidateAlternativeNDISNumber = debounce(this.debounceValidateAlternativeNDISNumber, 1000)
  }

  componentDidMount () {
    const { fetchClientLimit } = this.props
    fetchClientLimit({ loading: true })

    if (this.isEdit()) {
      this.fetchClient()
      // this.fetchLogs()
    } else {
      // this.checkActiveDisable()
    }
    this.fetchDetails()
    this.fetchSettings()
    this.fetchReasons()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )
    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    if (place && validator.isNotEmptyArray(place.address_components)) {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0]
        if (addressType === 'locality') {
          suburb = place.address_components[i]['long_name']
        } else if (addressType === 'postal_code') {
          postcode = place.address_components[i]['long_name']
        } else if (addressType === 'administrative_area_level_1') {
          state = place.address_components[i]['long_name']
        }
      }
    }

    // console.log(place.geometry.location.lat(), place.geometry.location.lng())

    this.setState({ latitude: place.geometry ? place.geometry.location.lat() : null, longitude: place.geometry ? place.geometry.location.lng() : null })
    this.props.form.setFieldsValue({ address: place.formatted_address, postcode, state, suburb })
  }

  closeLinkModal = () => {
    const { form } = this.props
    const { loadingLinkClientId } = this.state

    if (loadingLinkClientId) {
      return
    }

    this.setState({ pcpUser: {}, showLinkModal: false })
    form.resetFields()
  }

  openLinkModal = () => {
    this.setState({ showLinkModal: true })
  }

  render () {
    const { clientLimit, form, history } = this.props
    const {
      currentTab, item, loading, loadingSave, loadingLinkClientId, pcpUser, showSave, showEdit, showLinkModal,
      similarFieldCount, similarFieldPercent
    } = this.state
    const { getFieldDecorator } = form
    const clientId = this.getId()
    const clientRefId = this.getRefId()
    const isInfoTab = currentTab === '1'
    const isItem = item && item.id && item.pm_is_editable

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Participant (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : !isInfoTab ? `${item.first_name} ${item.last_name}` : showEdit ? `${item.first_name} ${item.last_name} (View Only)` : showSave ? `${item.first_name} ${item.last_name} (Edit Mode)` : 'Participant'}>

            {this.isEdit() && isItem && this.hasAccess(Permissions.PARTICIPANT.INFO.DELETE)
              ? showSave && isInfoTab && !loading
                ? (
                  <div className='btn btn-ghost' onClick={this.handlePreDelete} style={{ marginRight: 20 }}>
                    Delete
                  </div>
                )
                : null
              : null}

            {showEdit && this.isEdit() && isItem && this.hasAccess(Permissions.PARTICIPANT.INFO.UPDATE) && !loading && isInfoTab
              ? (
                <div className='btn' onClick={this.handleEditButton}>
                  Edit
                </div>)
              : null}

            {(showSave && this.isEdit() && isItem && this.hasAccess(Permissions.PARTICIPANT.INFO.UPDATE)) && !loading && isInfoTab
              ? (
                item.pu_id ? (
                  <div className='btn' onClick={this.handleUnlinkClient}>
                    Unlink Portal ID
                  </div>
                ) : (
                  <div className='btn' onClick={this.openLinkModal}>
                    Link Portal ID
                  </div>
                )
              )
              : null}

            {((!this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INFO.CREATE) && clientLimit && clientLimit.can_add) ||
              (showSave && this.isEdit() && isItem && this.hasAccess(Permissions.PARTICIPANT.INFO.UPDATE))) && !loading && isInfoTab
              ? (
                <div className='btn' onClick={this.handleSave}>
                  Save
                </div>)
              : null}

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>
          <div className={`client-panel`}>
            <Spin spinning={loadingSave}>
              {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INFO.READ)
                ? <div className={`client-panel-header ${item && item.active === false ? 'disabled' : ''}`}>
                  {loading
                    ? <Row>
                      <Col lg={3} style={{ textAlign: 'center' }}>
                        <div className='client-panel-header-skeleton-avatar' />
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-skeleton' />
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-skeleton' />
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-skeleton' />
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-skeleton' />
                      </Col>
                      <Col lg={4} />
                    </Row>
                    : <div>
                      <Row>
                        <Col lg={3} style={{ textAlign: 'center' }}>
                          {item.gender ? <img alt='' src={process.env.PUBLIC_URL + '/img/' + (item.gender && item.gender.toLowerCase() === 'male' ? 'male.png' : 'female.png')} className='avatar' /> : null}
                        </Col>
                        <Col lg={4}>
                          <div className='client-panel-header-label'>Name</div>
                          <div className='client-panel-header-value'>{item.first_name} {item.last_name}<div className='client-panel-header-subvalue'>{item.acc_ref}</div></div>
                        </Col>
                        <Col lg={4}>
                          <div className='client-panel-header-label'>DOB</div>
                          <div className='client-panel-header-value'>{formatter.toShortDate(item.dob) || '-'}</div>
                        </Col>
                        <Col lg={4}>
                          <div className='client-panel-header-label'>NDIS Number</div>
                          <div className='client-panel-header-value'>{item.ndis_number || '-'}</div>
                        </Col>
                        <Col lg={4}>
                          <div className='client-panel-header-label'>Inv Auth</div>
                          <div className='client-panel-header-value'>{`${item.pm_is_auth_req ? 'YES' : `NO (${item.pm_auth_amount ? `Max ${formatter.toPrice(item.pm_auth_amount)}` : 'No Limit'})`}`}</div>
                        </Col>
                      </Row>
                    </div>}
                </div>
                : null}
              <div className='client-panel-body'>
                <Tabs
                  defaultActiveKey={currentTab}
                  onChange={this.handleTabChange}
                >
                  <TabPane tab='Participant Info' key='1'>
                    {this.infoTab()}
                  </TabPane>
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INFO.READ)
                    ? <TabPane tab='Custom Identifier' key='8'>
                      <CustomIdentifierList key={`cidftab${currentTab}`} genreId={clientId} genre={'participant'} history={this.props.history} permission={Permissions.PARTICIPANT.INFO.UPDATE} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.SB.LIST)
                    ? <TabPane tab='Service Booking' key='3'>
                      <ClientBudget key={`bstab${currentTab}`} clientId={clientId} clientRefId={clientRefId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.PP_PACE.LIST)
                    ? <TabPane tab='(PACE) Plan Period' key='9'>
                      <ClientPlanPeriod key={`pptab${currentTab}`} clientId={clientId} clientRefId={clientRefId} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.CREDIT.LIST)
                    ? <TabPane tab='(PRODA) Credits' key='7'>
                      <ClientCredit key={`crdtab${currentTab}`} clientId={clientId} clientRefId={clientRefId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.CREDIT_PACE.LIST)
                    ? <TabPane tab='(PACE) Credits' key='10'>
                      <ClientCreditPace key={`crdtab${currentTab}`} clientId={clientId} clientRefId={clientRefId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INVOICE.LIST)
                    ? <TabPane tab='(PRODA) Invoices' key='4'>
                      <ClientInvoice key={`invtab${currentTab}`} clientId={clientId} clientRefId={clientRefId} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INVOICE_PACE.LIST)
                    ? <TabPane tab='(PACE) Invoices' key='11'>
                      <ClientInvoicePace key={`crdtab${currentTab}`} clientId={clientId} clientRefId={clientRefId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.STMTS.LIST)
                    ? <TabPane tab='Statements' key='5'>
                      <ClientStatement key={`stbtab${currentTab}`} clientId={clientId} clientRefId={clientRefId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.FILES.LIST)
                    ? <TabPane tab='Files' key='6'>
                      <ClientFile key={`filetab${currentTab}`} clientId={clientId} clientRefId={clientRefId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INFO.READ)
                    ? <TabPane tab='Activity Log' key='2'>
                      <ClientActivity key={`actab${currentTab}`} clientId={clientId} clientRefId={clientRefId} history={this.props.history} />
                    </TabPane>
                    : null}
                </Tabs>
              </div>
            </Spin>
          </div>
        </Page.Content>

        <Modal
          title={`Link Portal ID${pcpUser.user_id ? ': ' : ''}${formatter.toPcpUserId(pcpUser.user_id)}`}
          visible={showLinkModal}
          cancelButtonProps={{
            loading: loadingLinkClientId
          }}
          okButtonProps={{
            disabled: !pcpUser || !pcpUser.id || pcpUser.id < 0,
            loading: loadingLinkClientId
          }}
          okText='Link'
          onCancel={this.closeLinkModal}
          onOk={this.handleLinkClient}
        >
          <Form>
            <Row gutter={16}>
              <Col lg={12}>
                <FormItem label='Enter code from participant' hasFeedback>
                  {getFieldDecorator('otp', {
                    rules: [
                      { required: showLinkModal, message: 'Please enter code' },
                      { whitespace: showLinkModal, message: 'Please enter code' },
                      { len: 6, message: 'code must be 6 characters' },
                    ]
                  })(
                    <Input className='otp-input' maxLength={6} />
                  )}
                </FormItem>
              </Col>

              <Col lg={12}>
                <FormItem colon={false} label=' '>
                  <Button type='primary' loading={loadingLinkClientId} onClick={this.handleVerifyOtp}>Verify</Button>
                </FormItem>
              </Col>
            </Row>

            {pcpUser && pcpUser.id > 0 ? (
              <>
                <FormItem label='Name' hasFeedback>
                  {getFieldDecorator('pu_name', {
                    rules: [
                      { validator: this.validatePuName }
                    ]
                  })(
                    <Input readOnly />
                  )}
                </FormItem>

                <Row gutter={16}>
                  <Col lg={12}>
                    <FormItem label='D.O.B' hasFeedback>
                      {getFieldDecorator('pu_dob', {
                        rules: [
                          { validator: this.validatePuDob }
                        ]
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                  </Col>

                  <Col lg={12}>
                    <FormItem label='Email'>
                      {getFieldDecorator('pu_email', {
                        rules: [
                          { validator: this.validatePuEmail }
                        ]
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col lg={12}>
                    <FormItem label='Gender'>
                      {getFieldDecorator('pu_gender', {
                        rules: [
                          { validator: this.validatePuGender }
                        ]
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                  </Col>

                  <Col lg={12}>
                    <FormItem label='NDIS Number'>
                      {getFieldDecorator('pu_ndis_number', {
                        rules: [
                          { validator: this.validatePuNdisNumber }
                        ]
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <FormItem
                  colon={false}
                  label={`${similarFieldCount} / ${totalComparisonFields} fields matches (${similarFieldPercent} similarity)`}
                  style={{ marginBottom: 0 }}
                />
              </>
            ) : null}
          </Form>
        </Modal>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { activeMsg, currentAge, loading, loadingCheckNDISNo, loadingCheckAltNDISNo, item, providerList, providerInfo, genders, heights, languages, relationships, reasons, suburbs, shouldActive, showAuthorizeRep, showSupportCoord, showPMAuthorizeAmount, showExitedReason, showExitedToggle, isExitedToggleChange } = this.state

    const isAuthTargetRequired = (getFieldValue('pm_is_auth_req') === true) || (getFieldValue('pm_is_auth_req') === false && !isNaN(parseFloat(getFieldValue('pm_auth_amount'))) && parseFloat(getFieldValue('pm_auth_amount')) > 0)
    const isRmtRecipientRequired = (this.isEmpty(getFieldValue('pm_bsb'))) || (this.isEmpty(getFieldValue('pm_bank_acc_no')))

    return (
      <Form>
        <Loading loading={loading} blur>
          <Panel title='Account'>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Portal ID'>
                  {getFieldDecorator('pu_user_id', {
                    initialValue: item.portal_user ? formatter.toPcpUserId(item.portal_user.user_id) : undefined
                  })(
                    <Input readOnly placeholder='Not linked' />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Active' extra={activeMsg}>
                  {getFieldDecorator('active', {
                    initialValue: item.active !== undefined ? item.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      onChange={this.handleActiveChange}
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    // disabled={shouldActive || item.is_exited}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            {/* <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                  {getFieldDecorator('acc_ref', {
                    initialValue: item.acc_ref
                  })(
                    <Input readOnly />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Active' extra={activeMsg}>
                  {getFieldDecorator('active', {
                    initialValue: item.active !== undefined ? item.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      onChange={this.handleActiveChange}
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    // disabled={shouldActive || item.is_exited}
                    />
                  )}
                </FormItem>
              </Col>
            </Row> */}

            {/* <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Service Agreement Date' hasFeedback>
                  {getFieldDecorator('service_agreement_date', this.isEdit() && item.service_agreement_date ? {
                    initialValue: item.service_agreement_date ? formatter.toMoment(item.service_agreement_date) : null
                  } : {})(
                    <DatePicker format={dateFormat} onChange={this.handleSADateChange} />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                {this.isEdit() && showExitedToggle && this.hasAccess('updateClientExited')
                  ? <FormItem {...sideBySideFormItemLayout} label='Exited'>
                    {getFieldDecorator('is_exited', {
                      initialValue: item.is_exited || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        onChange={() => this.setState({showExitedReason: true, isExitedToggleChange: true})}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                      />
                    )}
                  </FormItem>
                  : null}
              </Col>
            </Row> */}
          </Panel>

          <Panel title='Information'>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='First Name' hasFeedback>
                  {getFieldDecorator('first_name', {
                    initialValue: item.first_name,
                    rules: [
                      { min: 2, message: 'First Name must be between 2 and 128 characters' },
                      { max: 128, message: 'First Name must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter first name' },
                      { whitespace: true, message: 'Please enter first name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Last Name' hasFeedback>
                  {getFieldDecorator('last_name', {
                    initialValue: item.last_name,
                    rules: [
                      { min: 2, message: 'Last Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Last Name must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter last name' },
                      { whitespace: true, message: 'Please enter last name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Gender' hasFeedback>
                  {getFieldDecorator('gender', {
                    initialValue: item.gender,
                    rules: [
                      { required: true, message: 'Please select gender.' }
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        genders.map((gender) => {
                          return <Option key={`gendes-${gender.id}`} value={gender.value}>{gender.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={19}>
                    <FormItem {...sideBySideExtraFormItemLayout} label='Date Of Birth' hasFeedback>
                      {getFieldDecorator('dob', {
                        initialValue: item.dob ? Moment(item.dob) : null,
                        rules: [
                          { required: true, message: 'Please select dob.' }
                        ]
                      })(
                        <DatePicker defaultPickerValue={Moment(new Date())} format={dateFormat} onChange={this.handleDOBChange} />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={5}>
                    <div className='client_age'>
                      {currentAge} years old
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Mobile Number' hasFeedback>
                  {getFieldDecorator('mobile_phone', {
                    initialValue: item.mobile_phone,
                    rules: [
                      { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                      { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter phone number' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='NDIS Number'>
                  {getFieldDecorator('ndis_number', {
                    initialValue: item.ndis_number || null,
                    rules: [
                      { required: true, message: 'Please enter NDIS Number' },
                      { whitespace: true, message: 'Please enter NDIS Number' },
                      { validator: this.validateNDISNoEntry }
                    ]
                  })(
                    <Input
                      onChange={(e) => this.validateNDISNumber(e)}
                      addonAfter={loadingCheckNDISNo ? <Icon type={'loading'} className='wd-loading-icon' /> : <span style={{ marginRight: '13px' }} />}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={{ span: 12, offset: 12 }}>
                <FormItem {...sideBySideFormItemLayout} label='Alternative NDIS Number'>
                  {getFieldDecorator('alt_ndis_number', {
                    initialValue: item.alt_ndis_number || null,
                    rules: [
                      { whitespace: true, message: 'Please enter Alternative NDIS Number' },
                      { validator: this.validateAlternativeNDISNoEntry }
                    ]
                  })(
                    <Input
                      onChange={(e) => this.validateAlternativeNDISNumber(e)}
                      addonAfter={loadingCheckAltNDISNo ? <Icon type={'loading'} className='wd-loading-icon' /> : <span style={{ marginRight: '13px' }} />}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                  {getFieldDecorator('phone', {
                    initialValue: item.phone,
                    rules: [
                      { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                      { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter phone number' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                  {getFieldDecorator('email', {
                    initialValue: item.email,
                    rules: [
                      { type: 'email', message: 'Please provide a valid Email' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Has Authorized Rep. ?'>
                  {getFieldDecorator('authorize_rep', {
                    initialValue: item.authorize_rep || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={this.handleAuthorizeRepChange}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Have Support Coordinator ?'>
                  {getFieldDecorator('pm_support_coord', {
                    initialValue: item.pm_support_coord || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={this.handleSupportCoordChange}
                    />
                  )}
                </FormItem>
              </Col>
              {/* <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Height' hasFeedback>
                  {getFieldDecorator('height', {
                    initialValue: item.height,
                    rules: [
                      // { required: true, message: 'Please select height' }
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        heights.map((height, idx) => {
                          return <Option key={height.value}>{height.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col> */}
            </Row>
          </Panel>

          {showAuthorizeRep
            ? <div className='show-authorize-field'>
              <Panel title='Authorized Representative'>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Name'>
                      {getFieldDecorator('authorize_rep_name', {
                        initialValue: item.authorize_rep_name || null
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Contact'>
                      {getFieldDecorator('authorize_rep_phone', {
                        initialValue: item.authorize_rep_phone,
                        rules: [
                          { min: 2, message: 'Contact must be between 2 and 128 characters' },
                          { max: 128, message: 'Contact must be between 2 and 128 characters' },
                          { whitespace: true, message: 'Please enter contact' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Email'>
                      {getFieldDecorator('authorize_rep_email', {
                        initialValue: item.authorize_rep_email || null
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                      {getFieldDecorator('authorize_rep_relationship', {
                        initialValue: item.authorize_rep_relationship
                      })(
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {
                            relationships.map((relation, idx) => {
                              return <Option key={`relas-${relation.id}`}>{relation.name}</Option>
                            })
                          }
                        </Select>
                      )}
                      {/* {getFieldDecorator('authorize_rep_relationship', {
                        initialValue: item.authorize_rep_relationship
                      })(
                        <Input />
                      )} */}
                    </FormItem>
                  </Col>

                </Row>

              </Panel>
            </div>
            : null
          }

          {showSupportCoord
            ? <div className='show-authorize-field'>
              <Panel title='Support Coordinator'>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Name'>
                      {getFieldDecorator('pm_support_coord_name', {
                        initialValue: item.pm_support_coord_name || null
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Contact'>
                      {getFieldDecorator('pm_support_contact', {
                        initialValue: item.pm_support_contact,
                        rules: [
                          { min: 2, message: 'Contact must be between 2 and 128 characters' },
                          { max: 128, message: 'Contact must be between 2 and 128 characters' },
                          { whitespace: true, message: 'Please enter contact' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Email'>
                      {getFieldDecorator('pm_support_coord_email', {
                        initialValue: item.pm_support_coord_email || null
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Organisation'>
                      {getFieldDecorator('pm_support_coord_org', {
                        initialValue: item.pm_support_coord_org || null
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                </Row>

              </Panel>
            </div>
            : ''
          }

          <Panel title='Communication'>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Invoice Authorisation Required?'>
                  {getFieldDecorator('pm_is_auth_req', {
                    initialValue: this.isEdit() ? item.pm_is_auth_req : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={this.handlePMAuthorizationAmt}
                    />
                  )}
                </FormItem>
              </Col>
              {showPMAuthorizeAmount ? (
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Max Invoiced Amt'>
                    {getFieldDecorator('pm_auth_amount', {
                      initialValue: formatter.toPriceValue(item.pm_auth_amount) || null,
                      rules: [
                        // { required: true, message: ' ' },
                        { validator: this.validatePMAuthAmountChange }
                      ]
                    })(
                      <Input
                        addonBefore={'$'}
                        onPaste={this.handlePasteAmt}
                      />
                    )}
                  </FormItem>
                </Col>
            ) : null}
            </Row>

            <Row>
              <Col>
                {showPMAuthorizeAmount ? (
                  <FormItem
                    {...formItemLayout}
                    label='Except these Provider(s)'>
                    {getFieldDecorator(`need_auth_provider_ids`, {
                      initialValue: item.need_auth_provider_ids || undefined
                    })(
                      <Select
                        mode='multiple'
                        showSearch
                        placeholder='Select Provider'
                        optionFilterProp='children'
                        notFoundContent='No providers available'
                        filterOption={(input, option) => this.findProvider(input, option)}
                        onChange={(id) => this.onSelectProvider(id)}
                      >
                        {providerList.map((itm, idx) => <Option key={`pts${idx}`} value={itm.id}>{itm.fullname}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                ) : (
                  <FormItem
                    {...formItemLayout}
                    label='Except these Provider(s)'>
                    {getFieldDecorator(`skip_auth_provider_ids`, {
                      initialValue: item.skip_auth_provider_ids || undefined
                    })(
                      <Select
                        mode='multiple'
                        showSearch
                        placeholder='Select Provider'
                        optionFilterProp='children'
                        notFoundContent='No providers available'
                        filterOption={(input, option) => this.findProvider(input, option)}
                        onChange={(id) => this.onSelectProvider(id)}
                      >
                        {providerList.map((itm, idx) => <Option key={`pts${idx}`} value={itm.id}>{itm.fullname}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                )}
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Inv Auth Email' hasFeedback>
                  {getFieldDecorator('pm_auth_email', {
                    initialValue: item.pm_auth_email || null,
                    rules: [
                      { required: true, message: ' ' },
                      { validator: (r, v, c) => this.validateEmailMultiple(r, v, c, true) }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Statement Email' hasFeedback>
                  {getFieldDecorator('pm_statement_email', {
                    initialValue: item.pm_statement_email || null,
                    rules: [
                      { required: true, message: ' ' },
                      { validator: (r, v, c) => this.validateEmailMultiple(r, v, c, true) }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              {/* <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Comm Email Addressee' hasFeedback>
                  {getFieldDecorator('pm_auth_email_name', {
                    initialValue: item.pm_auth_email_name || null,
                    rules: [
                      { required: true, message: 'Please enter email addressee' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col> */}
            </Row>
          </Panel>

          {/* <Panel title='Statement Recipient'>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Recipient Email' hasFeedback>
                  {getFieldDecorator('pm_statement_email', {
                    initialValue: item.pm_statement_email || null,
                    rules: [
                      { required: true, message: ' ' },
                      { validator: (r, v, c) => this.validateEmailMultiple(r, v, c, true) }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Recipient Addressee' hasFeedback>
                  {getFieldDecorator('pm_statement_email_name', {
                    initialValue: item.pm_statement_email_name || null,
                    rules: [
                      { required: true, message: 'Please enter email addressee' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel> */}

          <Panel title='Banking Information'>
            <Row gutter={16}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label={`Account's Name`} hasFeedback extra='In ABA, the account name will be limited to the first 32 characters only.'>
                  {getFieldDecorator('pm_acc_name', {
                    initialValue: item.pm_acc_name || null,
                    rules: [
                      { min: 2, message: 'Account\'s Name must be between 2 and 64 characters' },
                      { max: 64, message: 'Account\'s Name must be between 2 and 64 characters' },
                      // { required: true, message: 'Please enter Account\'s Name' },
                      { whitespace: true, message: 'Please enter Account\'s Name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='BSB' hasFeedback extra='Must be in NNN-NNN format'>
                  {getFieldDecorator('pm_bsb', {
                    initialValue: item.pm_bsb || null,
                    rules: [
                      // { required: true, message: ' ' },
                      { validator: this.validateBSB }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Account Number' hasFeedback>
                  {getFieldDecorator('pm_bank_acc_no', {
                    initialValue: item.pm_bank_acc_no || null,
                    rules: [
                      // { required: true, message: ' ' },
                      { validator: this.validateBankAccNo }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Remittance Recipient Email' hasFeedback>
                  {getFieldDecorator('pm_rmt_email', {
                    initialValue: item.pm_rmt_email || null,
                    rules: [
                      { required: isRmtRecipientRequired, message: ' ' },
                      { validator: this.validateRemittanceRecipientEmail(isRmtRecipientRequired) }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Remittance Recipient Addressee' hasFeedback>
                  {getFieldDecorator('pm_rmt_email_name', {
                    initialValue: item.pm_rmt_email_name || null,
                    rules: [
                      { required: isRmtRecipientRequired, message: 'Please enter email addressee' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>

          <Panel title='Preferences'>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Interpreter Required?'>
                  {getFieldDecorator('pref_is_interpreter_required', {
                    initialValue: this.isEdit() ? item.pref_is_interpreter_required : false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <FormItem {...formItemLayout} label='Preferred Languages' hasFeedback>
                  {getFieldDecorator('preference.languages', {
                    initialValue: item.preference && validator.isNotEmptyArray(item.preference.languages) ? item.preference.languages : []
                  })(
                    <Select
                      mode='multiple'
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        languages.map((language) => {
                          return <Option key={`lang${language.id}`} value={language.id}>{language.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>

          <Panel title='Alerts'>
            <Row>
              {/* <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Public Alert'>
                  {getFieldDecorator('public_alert', {
                    initialValue: item.public_alert || null
                  })(
                    <TextArea rows={4} />
                  )}
                </FormItem>
              </Col> */}
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Private Alert'>
                  {getFieldDecorator('private_alert', {
                    initialValue: item.private_alert || null
                  })(
                    <TextArea rows={4} />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>

          <Panel title='Address'>
            <FormItem {...formItemLayout} label='Address' hasFeedback>
              {getFieldDecorator('address', {
                initialValue: item.address,
                rules: [
                  { min: 2, message: 'Address must be between 2 and 128 characters' },
                  { max: 128, message: 'Address must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter address' },
                  { whitespace: true, message: 'Please enter address' }
                ]
              })(
                <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
              {getFieldDecorator('unit_building', {
                initialValue: item.unit_building,
                rules: [
                  { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                  { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                  { whitespace: true, message: 'Please enter unit/building info' }
                ]
              })(
                <Input placeholder='Please Enter Unit No/Building Name' />
              )}
            </FormItem>

            <Row style={{ display: 'none' }}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                  {getFieldDecorator('suburb', {
                    initialValue: item.suburb
                  })(
                    <Input disabled />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Country'>
                  {getFieldDecorator('country', {
                    initialValue: 'Australia'
                  })(
                    <Input disabled />
                  )}
                </FormItem>

              </Col>
            </Row>

            <Row style={{ display: 'none' }}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='State'>
                  {getFieldDecorator('state', {
                    initialValue: item.state || null
                  })(
                    <Select placeholder='Please select a state' disabled>
                      {
                        AustralianStates.map((states, idx) => (
                          <Option key={`stx-${states.value}`} value={states.value}>{states.name}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Postcode' hasFeedback>
                  {getFieldDecorator('postcode', {
                    initialValue: item.postcode
                  })(
                    <Input disabled />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Rate Region' hasFeedback>
                  {getFieldDecorator('suburb_select', {
                    initialValue: item.suburb_select,
                    rules: [
                      { required: true, message: 'Please select rate region' }
                    ]
                  })(
                    <Select
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        suburbs.map((s, idx) => {
                          return <Option key={`subus-${s.value}`} value={s.value}>{s.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>

          </Panel>

          <Panel title='Second Contact'>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Name'>
                  {getFieldDecorator('second_contact_name_1', {
                    initialValue: item.second_contact_name_1,
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Name must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                  {getFieldDecorator('second_contact_phone_1', {
                    initialValue: item.second_contact_phone_1,
                    rules: [
                      { min: 2, message: 'Contact must be between 2 and 128 characters' },
                      { max: 128, message: 'Contact must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter contact' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>

            </Row>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                  {getFieldDecorator('second_contact_relationship_1', {
                    initialValue: item.second_contact_relationship_1,
                    rules: [
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        relationships.map((relation, idx) => {
                          return <Option key={`rsdx-${relation.value}`} value={relation.value}>{relation.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Email'>
                  {getFieldDecorator('second_contact_email_1', {
                    initialValue: item.second_contact_email_1
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
            <hr className='contact-separator' />
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Name'>
                  {getFieldDecorator('second_contact_name_2', {
                    initialValue: item.second_contact_name_2,
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Name must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                  {getFieldDecorator('second_contact_phone_2', {
                    initialValue: item.second_contact_phone_2,
                    rules: [
                      { min: 2, message: 'Contact must be between 2 and 128 characters' },
                      { max: 128, message: 'Contact must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter contact' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                  {getFieldDecorator('second_contact_relationship_2', {
                    initialValue: item.second_contact_relationship_2,
                    rules: [
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        relationships.map((relation, idx) => {
                          return <Option key={relation.value}>{relation.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Email'>
                  {getFieldDecorator('second_contact_email_2', {
                    initialValue: item.second_contact_email_2
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>
          <SideModal
            buttons={[
              <Button key='0' type='primary' onClick={() => this.handleExitedReason(false)}>Submit</Button>
            ]}
            onClose={this.handleCancelExitedReason}
            showModal={showExitedReason}
            title='You Have Changed Active Status'
          >
            <FormItem label='Reason'>
              {getFieldDecorator('exited_reason', {
                rules: [
                  isExitedToggleChange ? { required: true, message: 'Please select reason' } : {}
                ]
              })(
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    reasons.map((reason) => {
                      return <Option key={`${reason.name}`}>{reason.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem label='Note'>
              {getFieldDecorator('exited_reason_note', {
                rules: [
                  // isExitedToggleChange ? { required: true, message: 'Please enter note' } : {}
                ]
              })(
                <TextArea rows={4} />
              )}
            </FormItem>
          </SideModal>
        </Loading>
      </Form>
    )
  }

  // checkActiveDisable = (value = undefined) => {
  //   const { form } = this.props
  //   const date = value === undefined ? form.getFieldValue('service_agreement_date') : value
  //   let status = false

  //   if (date) {
  //     status = false
  //   } else {
  //     form.setFieldsValue({ active: false })
  //     status = true
  //   }

  //   this.setState({shouldActive: status})
  // }

  onSelectProvider = (id) => {
    const { providerList } = this.state
    const p = providerList.find(e => e.id === id)
    if (p) {
      this.setState({ providerInfo: p })
    }
  }

  findProvider = (input, option) => {
    const pvd = `${option.props.children}`
    return pvd.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }


  fetchClient = async () => {
    if (!this.hasAccess(Permissions.PARTICIPANT.INFO.READ)) {
      return
    }

    try {
      const { form } = this.props
      const refId = this.getRefId()
      let id = null

      if (refId === 'add' || refId === 'new' || !refId) return

      this.setState({ loading: true })
      const item = await clientService.getRef(refId)


      if (item && item.id) {
        id = item.id
        // get current age
        this.handleAgeUpdate(item.dob)
        const defaultAuthorize = item.authorize_rep
        const defaultPMAuthorizeAmt = !item.pm_is_auth_req
        const defaultSupportCoord = item.pm_support_coord

        this.setState({
          item,
          loading: false,
          showAuthorizeRep: defaultAuthorize,
          showSupportCoord: defaultSupportCoord,
          showPMAuthorizeAmount: defaultPMAuthorizeAmt,
          // shouldActive: !item.service_agreement_date || false,
          showExitedToggle: !item.active
        })

        // BG0002 - remove service_agreement_date field completely
        // if (!item.service_agreement_date) {
        //   form.setFieldsValue({ active: false })
        // }

        // add this line to re-render the page to make Authorisation Required related inputs being shown after component updated
        this.setState({})
      } else {
        notify.error('Unable to get successfully', 'Unable to get participant successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to get successfully', 'Unable to get participant successfully. Please try again later.')
    }
  }

  fetchDetails = async () => {
    const { location } = this.props

    try {
      this.setState({ loading: true })
      const providers = await providerService.listAllProviders()

      this.setState({
        providerList: validator.isNotEmptyArray(providers) ? providers : [],
        loading: false
      })

      this.setState({})
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client list successfully. Please try again later.')
    }
  }

  fetchReasons = async () => {
    const filter = {}
    filter.type = { condition: '=', value: 'inactive' }
    filter.reason_code = { condition: '=', value: 'inactive' }

    const reasons = await settingReasonService.listReasonItemByPage(1, 0, filter)
    if (reasons && validator.isNotEmptyArray(reasons.list)) {
      this.setState({ reasons: reasons.list })
    } else {
      this.setState({ reasons: [] })
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'language' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'height' },
        { condition: '=', value: 'relationship' },
        { condition: '=', value: 'suburb-select' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      settings: settings.list,
      languages: settings.list.filter(item => item.identifier === 'language'),
      genders: settings.list.filter(item => item.identifier === 'gender'),
      heights: settings.list.filter(item => item.identifier === 'height'),
      relationships: settings.list.filter(item => item.identifier === 'relationship'),
      suburbs: settings.list.filter(item => item.identifier === 'suburb-select')
    })
  }

  handleActiveChange = (value) => {
    this.setState({ showExitedToggle: !value, activeMsg: '' })

    if (!value) {
      const { form } = this.props
      const { setFieldsValue } = form

      if (this.isEdit()) {
        this.setState({ showExitedReason: true, isExitedToggleChange: true })
      }

      // disabled due to request
      // setTimeout(() => { setFieldsValue({ is_exited: false }) }, 200)
    } else {
      if (this.isEdit()) {
        this.setState({ showExitedReason: false, isExitedToggleChange: false })
      }
    }
  }

  handlePreDelete = () => {
    const { loading } = this.state
    if (loading) return

    const { handleDelete } = this
    const id = this.getId()

    confirm({
      title: 'Delete this participant?',
      content: 'THIS ACTION CANNOT UNDO! Press Ok to continue, Cancel to return',
      async onOk () {
        handleDelete(id)
      },
      onCancel () {
      }
    })
  }

  handleDelete = async (id) => {
    const { item } = this.state
    const { fetchingClients, history } = this.props

    this.setState({ loadingSave: true })

    const r = await clientService.remove(id)

    if (r && r.id) {
      log.deleteClient(id, `Participant ${item.first_name} ${item.last_name} is deleted and all related Service Bookings are removed.`)

      this.handleDeleteSuccessful()
      history.replace('/participants')
      fetchingClients(true)
    } else {
      this.handleDeleteError()
    }

    this.setState({ loadingSave: false })
  }

  handleDeleteSuccessful = () => {
    notify.success('Deleted successfully', 'Participant deleted successfully.')
  }

  handleDeleteError = () => {
    notify.error('Unable to delete successfully', 'Unable to delete participant successfully. Please try again later.')
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({ currentTab: index })

    if (tab && tab.tabId) {
      this.props.history.replace(`/participants/${refId}${tab.path}`)
    }
  }

  // handleSADateChange = (e) => {
  //   this.checkActiveDisable(e)
  // }

  handleAuthorizeRepChange = (e) => {
    if (e === false) {
      this.setState({ showAuthorizeRep: false })
    } else {
      this.setState({ showAuthorizeRep: true })
    }
  }

  handleSupportCoordChange = (e) => {
    if (e === false) {
      this.setState({ showSupportCoord: false })
    } else {
      this.setState({ showSupportCoord: true })
    }
  }

  handlePMAuthorizationAmt = (e) => {
    if (e === true) {
      this.setState({ showPMAuthorizeAmount: false })
    } else {
      this.setState({ showPMAuthorizeAmount: true })
    }
  }

  handleDOBChange = (e) => {
    this.handleAgeUpdate(e)
  }

  handleAgeUpdate = (value) => {
    const empDate = Moment(value)
    const currYear = Moment()
    const ageNum = currYear.diff(empDate, 'years')

    this.setState({ currentAge: ageNum })
  }

  handleCancelExitedReason = () => {
    const { form } = this.props
    const { setFieldsValue } = form
    setFieldsValue({ active: true })
    this.handleActiveChange(true)
  }

  handleExitedReason (value) {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['exited_reason', 'exited_reason_note', 'active'], async (errors, values) => {
      if (!errors) {
        const activeMsg = values.active ? '' : `Reason: ${values.exited_reason}${values.exited_reason_note ? `, Note: ${values.exited_reason_note}` : ''}`
        this.setState({ showExitedReason: false, activeMsg: activeMsg })
      }
    })
  }

  handleLinkClient = () => {
    const { form } = this.props
    const { item, loadingLinkClientId, pcpUserShareToken, pcpUser } = this.state

    if (loadingLinkClientId) {
      return
    }

    confirm({
      title: 'Link this Portal ID?',
      content: <>Partcipant will be able to view their profile data in the Client Portal once linked.<br />Press Ok to continue, Cancel to return</>,
      onOk: async () => {
        form.validateFields(['otp'], async (errors) => {
          if (!errors) {
            this.setState({ loadingLinkClientId: true }, async () => {
              const result = await linkRequestService.linkClient({
                pu_id: pcpUser.id, pust_id: pcpUserShareToken.id, client_id: item.id
              })
              this.setState({ loadingLinkClientId: false }, () => {
                if (result && result.id > 0) {
                  this.closeLinkModal()
                  this.fetchClient()
                  notify.success('Linked successfully', 'Portal ID linked successfully')
                } else {
                  notify.error('Unable to link successfully', 'Unable to link Portal ID successfully. Please try again later')
                }
              })
            })
          }
        })
      }
    })
  }

  handlePasteAmt = (event) => {
    event.preventDefault()
    const { form } = this.props
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')

    if (pastedData) {
      const cleanedData = pastedData.replace(/[^0-9,.]/g, '')
      form.setFieldsValue({ pm_auth_amount: cleanedData })
      form.validateFieldsAndScroll(['pm_auth_amount']).catch(console.log)
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { setFieldsValue, validateFieldsAndScroll } = form
    let extraLog = ''

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingClients, history } = this.props
        const { item, longitude, latitude, providerInfo, loading, loadingSave, loadingCheckNDISNo, loadingCheckAltNDISNo } = this.state

        if (loading || loadingSave || loadingCheckNDISNo || loadingCheckAltNDISNo) {
          return
        }

        this.setState({ loadingSave: true })

        if (longitude && latitude) {
          values.longitude = longitude
          values.latitude = latitude
        }

        if (values.active) {
          values.is_exited = false
        }

        if (values.exited_reason && values.exited_reason !== '') {
          extraLog += `Inactive Reason "${values.exited_reason}" ${values.exited_reason_note ? `, Inactive Note "${values.exited_reason_note}"` : ''}`
        }

        // delete values.exited_reason
        // delete values.exited_reason_note

        if (values.pm_is_auth_req) {
          values.pm_auth_amount = null
        } else if (!values.pm_auth_amount && parseFloat(values.pm_auth_amount) !== 0) {
          values.pm_auth_amount = null
        }

        if (!values.suburb_select) {
          values.suburb_select = null
        }

        if (values.pm_bsb) {
          if (!(values.pm_bsb.indexOf('-') > -1)) {
            values.pm_bsb = values.pm_bsb.substring(0, 3) + '-' + values.pm_bsb.substring(3, 6)
          }
        } else {
          values.pm_bsb = null
        }

        if (!values.pm_acc_name) {
          values.pm_acc_name = null
        }

        if (!values.pm_bank_acc_no) {
          values.pm_bank_acc_no = null
        }

        if (values.pm_is_auth_req) {
          values.need_auth_provider_ids = null
          values.skip_auth_provider_ids = Array.isArray(values.skip_auth_provider_ids) && values.skip_auth_provider_ids.length > 0 ? values.skip_auth_provider_ids : null
        } else {
          values.skip_auth_provider_ids = null
          values.need_auth_provider_ids = Array.isArray(values.need_auth_provider_ids) && values.need_auth_provider_ids.length > 0 ? values.need_auth_provider_ids : null
        }

        try {
          if (this.isEdit()) {
            const r1 = await clientService.save(item.id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (r1 && r1.id) {
              log.updateClient(r1.id, item, values, ['exited_reason', 'exited_reason_note'],
                [
                  { key: 'pm_is_auth_req', label: 'Invoice Authorisation Required?' },
                  { key: 'pref_is_interpreter_required', label: 'Interpreter Required' },
                  { key: 'pm_auth_email', label: 'PM Inv Auth Email Recipient' },
                  { key: 'pm_auth_email_name', label: 'PM Inv Auth Email Recipient Addressee' },
                  { key: 'pm_statement_email', label: 'PM Statement Email Recipient' },
                  { key: 'pm_statement_email_name', label: 'PM Statement Email Recipient Addressee' },
                  { key: 'pm_bsb', label: 'BSB' },
                  { key: 'pm_bank_acc_no', label: 'Account Number' },
                  { key: 'pm_acc_name', label: 'Account\'s Name' },
                  { key: 'pm_rmt_email', label: 'PM Remittance Email Recipient' },
                  { key: 'pm_rmt_email_name', label: 'PM Remittance Email Addressee' },
                ],
                extraLog)
              this.handleSaveSuccessful()
              fetchingClients(true)
            } else {
              this.handleSaveError(r1)
            }
          } else {
            const r2 = await clientService.add(values)

            if (r2 && r2.id) {
              const { id, ref_id, acc_ref } = r2
              this.setState({ item: { ...item, ...values, id, ref_id, acc_ref } })
              log.addClient(id, `New participant ${values.first_name} ${values.last_name}`)
              this.handleSaveSuccessful()
              window.location.replace(`/participants/${ref_id}/info`)
              fetchingClients(true)
            } else {
              this.handleSaveError(r2)
            }
          }

          this.handleAgeUpdate(values.dob)
          setFieldsValue({ exited_reason: undefined, exited_reason_note: undefined })
          this.setState({ activeMsg: '' })

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          this.handleSaveError()
        }

        this.setState({ loadingSave: false })
      }
    })
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Participant saved successfully.')
  }

  handleSaveError = (r) => {
    notify.error('Unable to save successfully', r && r.errors ? formatter.toErrorMessage(r.errors) : 'Unable to save participant successfully. Please try again later.')
  }

  handleUnlinkClient = () => {
    const { item, loadingSave } = this.state

    if (loadingSave) {
      return
    }

    confirm({
      title: 'Unlink this Portal ID?',
      content: <>Participant will not be able to view their profile data in the Client Portal once unlinked.<br />Press Ok to continue, Cancel to return</>,
      onOk: async () => {
        this.setState({ loadingSave: true }, async () => {
          const result = await linkRequestService.unlinkClient({
            pu_id: item.pu_id, client_id: item.id
          })
          this.setState({ loadingSave: false }, () => {
            if (result && result.id > 0) {
              this.fetchClient()
              notify.success('Unlinked successfully', 'Portal ID unlinked successfully')
            } else {
              notify.error('Unable to unlink successfully', 'Unable to unlink Portal ID successfully. Please try again later')
            }
          })
        })
      }
    })
  }

  handleVerifyOtp = () => {
    const { form } = this.props
    const { item, loadingLinkClientId } = this.state

    if (loadingLinkClientId) {
      return
    }

    form.validateFields(['otp'], async (errors, values) => {
      if (!errors) {
        this.setState({ loadingLinkClientId: true }, async () => {
          const result = await linkRequestService.verifyOtp({ ...values, client_id: item.id })

          if (result && result.pu && result.pust && result.pu.id > 0 && result.pust.id > 0) {
            const { first_name: firstName, last_name: lastName, dob, email, gender, ndis_number: ndisNumber } = result.pu
            this.setState({ loadingLinkClientId: false, pcpUser: result.pu, pcpUserShareToken: result.pust }, () => {
              form.setFieldsValue({
                pu_name: `${firstName} ${lastName}`, pu_dob: formatter.toShortDate(dob), pu_email: email,
                pu_gender: formatter.capitalize(gender), pu_ndis_number: ndisNumber
              })
              const timer = setTimeout(() => {
                clearTimeout(timer)
                form.validateFields(['pu_name', 'pu_dob', 'pu_email', 'pu_gender', 'pu_ndis_number'], (errors) => {
                  const similarFieldCount = BigNumber(totalComparisonFields).minus(Object.keys(errors || {}).length).toFixed(0)
                  const similarFieldPercent = `${BigNumber(similarFieldCount).div(similarFieldPercent).times(100).toFixed()}%`
                  this.setState({ similarFieldCount, similarFieldPercent })
                })
              }, 200)
            })
          } else {
            this.setState({ loadingLinkClientId: false }, () => {
              notify.error('Invalid code', 'The code is invalid. Please try again with another code')
            })
          }
        })
      }
    })
  }

  validateEmailMultiple = (rule, value, callback, isRequired = false) => {
    const MSG_VALID_EMAIL = 'Please provide a valid email'
    const MSG_ENTER_EMAIL = 'Please enter email'

    if (value === null || value === undefined || value === '' || value.trim() === '') {
      if (isRequired) {
        callback(new Error(MSG_ENTER_EMAIL))
      } else {
        callback()
      }
    } else if (value && value.length > 0) {
      if (!validator.isEmailMultiple(value)) {
        callback(new Error(MSG_VALID_EMAIL))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  validateRemittanceRecipientEmail = (isRmtRecipientRequired) => (rule, value, callback) => {
    if (isRmtRecipientRequired) {
      return this.validateEmailMultiple(rule, value, callback, true)
    } else {
      callback()
    }
  }

  validateBSB = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      // callback(new Error('Please enter BSB'))
      callback() // non mandatory field in client
    } else if (!validator.isBSB(value)) {
      callback(new Error('BSB is invalid in format'))
    } else {
      callback()
    }
  }

  validateBankAccNo = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      // callback(new Error('Please enter Account Number'))
      callback() // non mandatory field in client
    } else if (!validator.isDigit(value) || value.length > 9) {
      callback(new Error('Account Number is invalid in format'))
    } else {
      callback()
    }
  }

  validatePMAuthAmountChange = (rule, value, callback) => {
    const pValue = parseFloat(value)
    if (value === null || value === undefined || value === '') {
      callback()
    } else if (pValue === 0) {
      callback(new Error(`Authorisation Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Authorisation is not number or decimal format`))
      } else {
        callback()
      }
    }
  }

  validateNDISNumber = (e) => {
    e.persist()
    const id = this.isEdit() ? this.getId() : null

    const value = e.target.value ? e.target.value.trim() : e.target.value
    this.debounceValidateNDISNumber(id, value)
  }

  validateNDISNoEntry = (rule, value, callback) => {
    const { isNDISNoDuplicated } = this.state
    if (isNDISNoDuplicated) {
      callback(new Error(NDISNoDuplicatedMsg))
    } else {
      callback()
    }
  }

  debounceValidateNDISNumber = async (id, value) => {
    const { form } = this.props
    const data = {
      id,
      ndis_number: value
    }


    if (value !== '') {
      // set the field value first and disabled all duplicated flags to temporary remove error alert
      form.setFields({ ndis_number: { value: value } })
      this.setState({ loadingCheckNDISNo: true, isNDISNoDuplicated: false })

      const r = await commonService.checkDuplicate('client', data)

      if (r && r.errors) {
        // if error (duplicate detected), flag is true and set error message on input. meanwhile, the validate function will set the input as errorneous input
        this.setState({ loadingCheckNDISNo: false, isNDISNoDuplicated: true })
        const w = window

        confirm({
          title: 'Possible Duplicated NDIS Number',
          content: (
            <div style={{ color: 'rgb(238, 27, 27)' }}>
              <p>There is a participant which has the same NDIS number with entered value.</p>
              <p>Press "Go to" button to check the duplicated NDIS number or press "OK" to edit.</p>
            </div>
          ),
          okText: 'Go to Duplicated Participant Page',
          cancelText: 'OK',
          onOk () {
            w.open(`${urlRedirect}/${r.ref_id}/info`)
          },
          onCancel () {
          }
        })

        // set the field value with errors
        form.setFields({ ndis_number: { value: value, errors: [{ message: NDISNoDuplicatedMsg }] } })
      } else {
        this.setState({ loadingCheckNDISNo: false, isNDISNoDuplicated: false })
      }
    }
  }

  validatePuName = (rule, value, callback) => {
    const { form } = this.props
    const firstName = form.getFieldValue('first_name')
    const lastName = form.getFieldValue('last_name')
    let tokenized = []

    if (typeof firstName === 'string' && firstName.trim().length > 0) {
      tokenized = tokenized.concat(firstName.split(/\s/g))
    }

    if (typeof lastName === 'string' && lastName.trim().length > 0) {
      tokenized = tokenized.concat(lastName.split(/\s/g))
    }

    tokenized = Array.from(new Set(tokenized))
    const puTokenized = Array.from(new Set(value.trim().split(/\s/g)))
    const total = tokenized.length
    let numOfMatch = 0

    for (const item of puTokenized) {
      if (tokenized.indexOf(item) > -1) {
        numOfMatch += 1
      }
    }

    if (numOfMatch >= total) {
      callback()
    } else if (numOfMatch < 1) {
      callback(`Name '${value}' is not the same as '${firstName} ${lastName}'`)
    } else {
      callback(`Name '${value}' is ${BigNumber(numOfMatch).div(total).times(100).toFixed(0)}% similar to '${firstName} ${lastName}'`)
    }
  }

  validatePuDob = (rule, value, callback) => {
    const { form } = this.props
    const dob = formatter.toShortDate(form.getFieldValue('dob'))

    if (value && value !== dob) {
      callback(`D.O.B '${value}' is not the same as '${dob}'`)
    } else {
      callback()
    }
  }

  validatePuEmail = (rule, value, callback) => {
    const { form } = this.props
    const email = form.getFieldValue('email')

    if (value && value !== email) {
      callback(`Email '${value}' is not the same as ${email || "''"}`)
    } else {
      callback()
    }
  }

  validatePuGender = (rule, value, callback) => {
    const { form } = this.props
    const gender = formatter.capitalize(form.getFieldValue('gender'))

    if (value && value !== gender) {
      callback(`Gender '${value}' is not the same as '${gender}'`)
    } else {
      callback()
    }
  }

  validatePuNdisNumber = (rule, value, callback) => {
    const { form } = this.props
    const ndisNumber = form.getFieldValue('ndis_number')

    if (value && value !== ndisNumber) {
      callback(`NDIS number '${value}' is not the same as '${ndisNumber}'`)
    } else {
      callback()
    }
  }

  validateAlternativeNDISNumber = (e) => {
    e.persist()
    const id = this.isEdit() ? this.getId() : null

    const value = e.target.value ? e.target.value.trim() : e.target.value
    this.debounceValidateAlternativeNDISNumber(id, value)
  }

  validateAlternativeNDISNoEntry = (rule, value, callback) => {
    const { isAltNDISNoDuplicated } = this.state
    if (isAltNDISNoDuplicated) {
      callback(new Error(AltNDISNoDuplicatedMsg))
    } else {
      callback()
    }
  }

  debounceValidateAlternativeNDISNumber = async (id, value) => {
    const { form } = this.props
    const data = {
      id,
      alt_ndis_number: value
    }


    if (value !== '') {
      // set the field value first and disabled all duplicated flags to temporary remove error alert
      form.setFields({ alt_ndis_number: { value: value } })
      this.setState({ loadingCheckAltNDISNo: true, isAltNDISNoDuplicated: false })

      const r = await commonService.checkDuplicate('client', data)

      if (r && r.errors) {
        // if error (duplicate detected), flag is true and set error message on input. meanwhile, the validate function will set the input as errorneous input
        this.setState({ loadingCheckAltNDISNo: false, isAltNDISNoDuplicated: true })
        const w = window

        confirm({
          title: 'Possible Duplicated Alternative NDIS Number',
          content: (
            <div style={{ color: 'rgb(238, 27, 27)' }}>
              <p>There is a participant which has the same Alternative NDIS number with entered value.</p>
              <p>Press "Go to" button to check the duplicated Alternative NDIS number or press "OK" to edit.</p>
            </div>
          ),
          okText: 'Go to Duplicated Participant Page',
          cancelText: 'OK',
          onOk () {
            w.open(`${urlRedirect}/${r.ref_id}/info`)
          },
          onCancel () {
          }
        })

        // set the field value with errors
        form.setFields({ alt_ndis_number: { value: value, errors: [{ message: AltNDISNoDuplicatedMsg }] } })
      } else {
        this.setState({ loadingCheckAltNDISNo: false, isAltNDISNoDuplicated: false })
      }
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  isEmpty = (value) => {
    return value !== null && value !== undefined && value !== ''
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }

  getPath = () => {
    const { match } = this.props
    const { params } = match
    const { path = '' } = params
    return path
  }
}

const mapDispatchToProps = {
  fetchingClients,
  fetchClientLimit,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Client))
