import Permissions from '../permissions'

export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
          {
            title: '(PRODA) Credits',
            icon: 'dollar',
            menu: [
              {
                name: '(PRODA) New Credit',
                link: '/credits/add',
                permission: Permissions.CREDIT.INFO.CREATE
              },
              {
                name: '(PRODA) List Credits',
                link: '/credits',
                permission: Permissions.CREDIT.INFO.LIST
              }
            ]
          },
          {
            title: 'Credits',
            icon: 'dollar',
            menu: [
              {
                name: 'New Credit',
                link: '/credits-pace/add',
                permission: Permissions.CREDIT.INFO_PACE.CREATE
              },
              {
                name: 'List Credits',
                link: '/credits-pace',
                permission: Permissions.CREDIT.INFO_PACE.LIST
              },
            ]
          }
        ]
      }
    ]
  }
]
