import moment from 'moment-timezone'

import { ExportType } from '../constants'
import { invoicePaceService } from '../services'
import formatter from './formatter'
import validator from './validator'

moment.tz.setDefault('Australia/Melbourne')

const fileDateFormat = 'YYYYMMDD'

async function downloadFile (response, filename) {
  if (response) {
    const blob = await response.blob()
    if (blob.type === 'application/json') {
      try {
        const response = await blob.text()
        return JSON.parse(response)
      } catch (e) {
        return e
      }
    } else {
      const href = window.URL.createObjectURL(blob)
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
  }
}

export default {
  downloadFile,
  async fetchExport (type, data) {
    let filename = ''
    let r = null

    if (type === 'invoice' && data) {
      const { start_date: startDate, end_date: endDate } = data
      const sd = formatter.toDate(startDate, fileDateFormat)
      const ed = formatter.toDate(endDate, fileDateFormat)
      filename = `PM-Invoice-${sd}-${ed}-${Date.now()}.zip`
      r = await invoicePaceService.exportInvoicePost(data)
    }

    if (r) {
      return downloadFile(r, filename)
    }
  },
  async fetchFile (file, selectedIds, isSendEmail, isDownload, customData) {
    if (validator.isObject(file) && validator.isId(file.id)) {
      const { id, type, created_at: createdAt } = file
      const date = createdAt ? formatter.toDate(createdAt, fileDateFormat) : formatter.toDate(moment(), fileDateFormat)
      let filename = ''

      if (type === ExportType.TYPE_PRODA) {
        filename = `PymtRequest-${date}-${Date.now()}.csv`
      } else if (type === ExportType.TYPE_PRODA_STD) {
        filename = `PymtRequest-Monthly-${date}-${Date.now()}.csv`
      } else if (type === ExportType.TYPE_PRODA_PM) {
        filename = `PymtRequest-PM-${date}-${Date.now()}.csv`
      } else if (type === ExportType.TYPE_SB_PRODA) {
        filename = `PymtRequest-Monthly-${date}-${Date.now()}.csv`
      } else if (type === ExportType.TYPE_SB_PRODA_V2) {
        filename = `PymtRequest-Monthly-${date}-${Date.now()}.csv`
      } else if (type === ExportType.TYPE_ABA) {
        filename = `ABA-${date}-${Date.now()}.aba`
      } else if (type === ExportType.TYPE_RMT) {
        filename = `Remittance-${date}-${Date.now()}.zip`
      } else if (type === ExportType.TYPE_PAYMENT) {
        filename = `Payment-${date}-${Date.now()}.zip`
      } else {
        filename = `File-${date}-${Date.now()}.csv`
      }

      const response = await invoicePaceService.exportInvoiceFilePost({
        id,
        type,
        periodId: (type === ExportType.TYPE_SB_PRODA || type === ExportType.TYPE_SB_PRODA_V2) ? id : undefined,
        selectedIds,
        isSendEmail,
        isDownload,
        customData
      })
      return downloadFile(response, filename)
    }
  },
  async fetchFileById (invoiceId, values) {
    if (validator.isObject(values) && validator.isId(invoiceId)) {
      const { type } = values
      const date = formatter.toDate(moment(), fileDateFormat)
      let filename = ''

      if (type === ExportType.TYPE_PRODA) {
        filename = `PymtRequest-${date}-${Date.now()}.csv`
      } else if (type === ExportType.TYPE_ABA) {
        filename = `ABA-${date}-${Date.now()}.aba`
      } else if (type === ExportType.TYPE_RMT) {
        filename = `Remittance-${date}-${Date.now()}.zip`
      }

      const response = await invoicePaceService.exportInvoiceFilePostById(invoiceId, values)
      return downloadFile(response, filename)
    }
  }
}
