import Permissions from '../permissions'

export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
          // {
          //   title: 'Invoices',
          //   icon: 'thunderbolt',
          //   menu: [
          //     {
          //       name: 'New Invoice',
          //       link: '/invoices/add',
          //       permission: Permissions.INVOICE.INFO.CREATE
          //     },
          //     {
          //       name: 'List Invoices',
          //       link: '/invoices-list/pm',
          //       permission: Permissions.INVOICE.INFO.LIST
          //     },
          //     {
          //       name: 'Manage Invoices (PM SB)',
          //       link: '/invoices-manage',
          //       permission: Permissions.INVOICE.MGMT.LIST
          //     },
          //     {
          //       name: 'Redownload - Payment Request',
          //       link: '/invoices-export/payment-request',
          //       permission: Permissions.INVOICE.MGMT.LIST
          //     },
          //     {
          //       name: 'Redownload - ABA/Remittance',
          //       link: '/invoices-export/aba-remittance',
          //       permission: Permissions.INVOICE.MGMT.LIST
          //     },
          //   ],
          // },
          {
            title: '(PRODA) Invoices',
            icon: 'thunderbolt',
            menu: [
              {
                name: '(PRODA) New Invoice',
                link: '/invoices/add',
                permission: Permissions.INVOICE.INFO.CREATE
              },
              {
                name: '(PRODA) List Invoices',
                link: '/invoices-list/pm',
                permission: Permissions.INVOICE.INFO.LIST
              },
              {
                name: '(PRODA) Redownload - Provider Invoice Payment Request',
                link: '/invoices-export/payment-request-pm',
                permission: Permissions.INVOICE.MGMT.LIST
              },
              {
                name: '(PRODA) Redownload - PM Fees Payment Request',
                link: '/invoices-export/payment-request-std',
                permission: Permissions.INVOICE.MGMT.LIST
              },
              {
                name: '(PRODA) Redownload - ABA/Remittance',
                link: '/invoices-export/aba-remittance',
                permission: Permissions.INVOICE.MGMT.LIST
              }
            ]
          },
          {
            title: 'Invoices',
            icon: 'thunderbolt',
            menu: [
              {
                name: 'New Invoice',
                link: '/invoices-pace/add',
                permission: Permissions.INVOICE.INFO_PACE.CREATE
              },
              {
                name: 'List Invoices',
                link: '/invoices-list-pace/pm',
                permission: Permissions.INVOICE.INFO_PACE.LIST
              },
              {
                name: 'Redownload - Provider Invoice Payment Request',
                link: '/invoices-export-pace/payment-request-pm',
                permission: Permissions.INVOICE.MGMT_PACE.LIST
              },
              {
                name: 'Redownload - PM Fees Payment Request',
                link: '/invoices-export-pace/payment-request-std',
                permission: Permissions.INVOICE.MGMT_PACE.LIST
              },
              {
                name: 'Redownload - ABA/Remittance',
                link: '/invoices-export-pace/aba-remittance',
                permission: Permissions.INVOICE.MGMT_PACE.LIST
              }
            ]
          }
        ]
      }
    ]
  }
]
