import Permissions from '../permissions'

export default [
  {
    name: '(PRODA) All Credits',
    desc: 'Manage credits',
    icon: 'profile',
    link: '/credits',
    enable: true,
    permission: Permissions.CREDIT.INFO.LIST
  },
  {
    name: 'All Credits',
    desc: 'Manage credits',
    icon: 'profile',
    link: '/credits-pace',
    enable: true,
    permission: Permissions.CREDIT.INFO_PACE.LIST
  }
]
